<template>
  <div class="custom-select" :class="isOpenSelect ? 'custom-select--open' : ''">
    <button class="custom-select__header" @click.prevent="isShowSelect()">
      <span class="custom-select__selected" v-if="selectedItem">
        {{ selectedItem }}
      </span>
      <span class="custom-select__selected" style="opacity: .5;" v-else>
        {{ label }}
      </span>
      <span class="custom-select__arrow">
        <svg class="icon icon-down ">
          <use xlink:href="#down"></use>
        </svg>
      </span>
    </button>

    <div class="custom-select__body">
      <label class="custom-select__option" v-for="item in items" :key="item.id">
        <input
          type="radio"
          name="custom-select"
          :value="item.code"
          :label="item.name"
          @input="selected()"
        />
        <span class="custom-select__label">
          {{ item.name }}
        </span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    items: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isOpenSelect: false,
      selectedCode: null,
      selectedItem: null,
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.items.forEach((element) => {
        if (element.default) {
          this.selectedCode = element.code;
          this.selectedItem = element.name;
        }
      });
    })
  },

  watch: {
    selectedItem() {
      this.$emit("update:modelValue", this.selectedCode);
    },
  },

  methods: {
    isShowSelect() {
      this.isOpenSelect = !this.isOpenSelect;
    },
    selected() {
      this.selectedCode = event.target.value;
      this.selectedItem = event.target.getAttribute('label');
      this.isOpenSelect = !this.isOpenSelect;
    },
  },
};
</script>
