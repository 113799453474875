<template>
  <div class="container">
    <div class="ordering-title">
      <div class="title">Оформление заказа</div>
    </div>

    <form @submit.prevent="confirmOrder">
      <div class="ordering-coupon ordering-section">
        <div class="ordering-coupon__input">
          <input
            type="text"
            name="coupon"
            placeholder="Применить купон"
            class="input"
            v-model="coupon"
          />
          <button class="ordering-coupon__btn" @click.prevent="applyCoupon()">
            <svg class="icon icon-arrow">
              <use xlink:href="#arrow"></use>
            </svg>
          </button>
        </div>
        <div class="ordering-coupon__list">
          <div
            v-for="(item, index) in coupons"
            :key="index"
            class="ordering-coupon__item"
          >
            <div class="ordering-coupon__item-info">
              <div>{{ item.coupon }}</div>
              <div>{{ item.name }}</div>
              <div class="ordering-coupon__item-status">{{ item.couponMessage }}</div>
            </div>
            <button class="ordering-coupon__btn-delete" @click.prevent="removeCoupon(item.coupon)">
              <img src="/img/icons/delete1.svg" alt=""/>
            </button>
          </div>
        </div>
      </div>

      <div class="delivery-tabs">
        <div
          class="ordering-delivery__title ordering__subtitle delivery-tab"
          :class="{ 'active': isDeliveryTab }"
          @click="isDeliveryTab = true"
        >Доставка
        </div>
        <div
          class="ordering-delivery__title ordering__subtitle delivery-tab"
          :class="{ 'active': !isDeliveryTab }"
          @click="isDeliveryTab = false"
        >Самовывоз
        </div>
      </div>


      <div class="ordering-pickup" v-if="!isDeliveryTab && shipmentPickup">
        <div v-html="shipmentPickup.text"></div>
        <br>

        <!--          <p>-->
        <!--            Вы можете самостоятельно забрать заказ со-->
        <!--            склада по адресу: г.Южно-Сахалинск, пр. Мира-->
        <!--            58/1 (<a href="https://yandex.ru/maps/80/yuzhno-sakhalinsk/house/prospekt_mira_58/ZU0EaABjTkECW0JuZWJ4dnxlZgw=/?ll=142.737457%2C46.970624&z=17" target="_blank">смотреть на карте</a>).-->
        <!--          </p>-->

        <!--          <p>-->
        <!--            Доставка осуществляется с 9:00 до 20:00-->
        <!--            (ежедневно).-->
        <!--          </p>-->
      </div>

      <div class="ordering-address ordering-section" v-if="isDeliveryTab">
        <div class="ordering-address__title ordering__subtitle">Адрес доставки</div>

        <div class="ordering-address__input-label">
          Город, улица, дом/строение, квартира
          <br>
          (Полный адрес доставки)
        </div>
        <div class="ordering-section">
          <button
            class="input btn-simple"
            type="button"
            @click="isModalActive = true"
          >
            {{ address.value }}
          </button>
          <input
            name="name"
            class="input"
            v-model="address.value"
            hidden
          />
        </div>

        <div class="ordering-address__inputs">
          <div class="ordering-address__input">
            <div class="ordering-address__input-label">Подъезд</div>
            <input
              name="name"
              type="text"
              placeholder="Ваш подъезд"
              class="input"
              v-model="entrance.value"
            />
          </div>
          <div class="ordering-address__input">
            <div class="ordering-address__input-label">Этаж</div>
            <input
              name="name"
              type="text"
              placeholder="Ваш этаж"
              class="input"
              v-model="floor.value"
            />
          </div>
        </div>

        <div class="ordering-address__more" v-if="specificsTravel.items">
          <div
            class="ordering-address__more-checkbox"
            v-for="(item, index) in specificsTravel.items"
            :key="index"
          >
            <label class="checkbox">
              <input
                name="checkbox"
                type="checkbox"
                :value="item.code"
                v-model="specificsTravelList"
              />
              <span class="checkbox__indicator">
                <svg class="icon icon-check">
                  <use xlink:href="#check"></use>
                </svg>
              </span>
              <span class="checkbox__description">{{ item.name }}</span>
            </label>
          </div>
        </div>
      </div>

      <div class="ordering-delivery ordering-section" v-if="deliveryTime.items && isDeliveryTab">
        <div class="ordering-delivery__title ordering__subtitle">Время доставки</div>
        <div class="ordering-delivery__row"
             v-for="(item, index) in deliveryTime.items"
             :key="index">
          <div class="ordering-delivery__row-title">{{ item.date }}</div>
          <div class="ordering-delivery__bullets">
            <div v-for="time in item.times" :key="time.code" class="ordering-delivery__bullet">
              <label class="time-bullet">
                <input
                  type="radio"
                  name="time-bullet"
                  :value="time.value"
                  :disabled="!time.active"
                  @input="selectDeliveryTime(time)"
                />
                <span class="time-bullet__description">
                    {{ time.value }}
                  </span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="ordering-payment ordering-section" v-if="payments.items">
        <div class="ordering-payment__title">Оплата</div>
        <div class="ordering-payment__select">
          <FormSelect
            :label="'Оплата'"
            :items="payments.items"
            v-model="paySystemValue"
          />
        </div>
        <div class="ordering-payment__message"></div>
      </div>

      <div class="ordering-recepient ordering-section">
        <div class="ordering-recepient__title ordering__subtitle">Покупатель</div>

        <div class="ordering-address__input-label">Ф.И.О.</div>
        <input
          type="text"
          class="input"
          required
          v-model="fio.value"
        />

        <div class="ordering-address__input-label">E-Mail</div>
        <input
          type="email"
          class="input"
          v-model="email.value"
        />

        <div class="ordering-address__input-label">Телефон</div>
        <input
          type="text"
          class="input"
          required
          v-model="phone.value"
          v-maska="'+# (###) ### ## ##'"
        />
      </div>
      <div class="ordering-bonuses ordering-section" v-if="account">
        <div class="ordering-bonuses__title">Внутренний счёт</div>
        <div class="ordering-bonuses__action">
          <div v-if="!isPayBudget" class="ordering-bonuses__action-span">{{ account.formatBudget }} ₽</div>
          <div v-else class="ordering-bonuses__action-span">{{ payBudgetTotal }} ₽</div>
          <button
            v-if="account.budget > 0"
            @click.prevent="payBudget()"
            class="ordering-bonuses__action-btn"
          >
            <span v-if="!isPayBudget">Списать</span>
            <span v-else>Отменить</span>
          </button>
        </div>
      </div>

      <div class="ordering-bonuses ordering-section" v-if="bonus">
        <div class="ordering-bonuses__writeoff">
          <div class="ordering-bonuses__subtitle">Бонусы на вашем счете: {{ bonus.bonus }}</div>
          <div class="ordering-bonuses__subtitle">Можно списать: {{ bonusMaxCash }}</div>
          <input
            type="number"
            placeholder="Количество бонусов"
            class="input"
            v-model.number="priceBonus"
            @change="changeBonus()"
          />
        </div>
      </div>

      <div class="ordering-replace ordering-section" v-if="badQuality.items">
        <div class="ordering-replace__title ordering__subtitle">
          В случае отсутствия или плохого качества товара мы можем:
        </div>
        <div class="ordering-replace__select">
          <FormSelect
            :label="'Выберите способ замены товара'"
            :items="badQuality.items"
            v-model="badQualityValue"
          />
        </div>
      </div>

      <div class="ordering-comment ordering-section">
        <div class="ordering-comment__title ordering__subtitle">Комментарий к заказу</div>
        <div class="ordering-comment__area">
          <FormArea
            :label="'Комментарий'"
            v-model="description"
          />
        </div>
      </div>

      <div class="cart-buy" v-if="orderTotal">
        <div class="container">
          <div class="cart-buy__wrapper">
            <div class="cart-buy__sum">
              <div class="cart-buy__sum-span">Итого:</div>
              <div class="cart-buy__sum-price">{{ orderTotalFinal() }} ₽</div>
              <div class="cart-buy__sum-price">Бонусы за заказ: {{ bonus.bonusEnrollment }}</div>
            </div>
            <div class="cart-buy__btn">
              <button class="btn">Оформить заказ</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <ModalAddress
    :is-active="isModalActive"
    :user-address="address.value"
    @closeModal="isModalActive = false"
  />
</template>

<script>
import store from "@/store/index.js";
import FormSelect from "@/components/forms/form-select";
import FormArea from "@/components/forms/textarea";
import ModalAddress from "@/components/modals/modal-address";

export default {
  beforeRouteEnter(to, from, next) {
    store.dispatch("CHECKOUT_FETCH_ORDERING").then(() => {
      next();
    });
  },

  components: {
    FormSelect,
    FormArea,
    ModalAddress
  },

  data() {
    return {
      coupon: '',
      description: '',
      deliveryDate: '',
      deliveryTimeTo: '',
      deliveryTimeFrom: '',
      specificsTravelList: [],
      priceBonus: null,
      bonusMaxCash: null,
      paySystemValue: null,
      badQualityValue: null,
      isPayBudget: false,
      isModalActive: false,
      isDeliveryTab: true,
    };
  },

  computed: {
    coupons() {
      return this.$store.state.checkout.ordering.coupons;
    },
    payments() {
      return this.$store.state.checkout.ordering.payments;
    },
    badQuality() {
      return this.$store.state.checkout.ordering.badQuality;
    },
    orderTotal() {
      return this.$store.state.checkout.ordering.orderTotal;
    },
    orderTotalFormat() {
      return this.$store.state.checkout.ordering.orderTotalFormat;
    },
    specificsTravel() {
      return this.$store.state.checkout.ordering.specificsTravel;
    },
    address() {
      return this.$store.state.checkout.ordering.address;
    },
    entrance() {
      return this.$store.state.checkout.ordering.entrance;
    },
    floor() {
      return this.$store.state.checkout.ordering.floor;
    },
    account() {
      return this.$store.state.checkout.ordering.account;
    },
    bonus() {
      return this.$store.state.checkout.ordering.bonus;
    },
    fio() {
      return this.$store.state.checkout.ordering.fio
    },
    phone() {
      return this.$store.state.checkout.ordering.phone;
    },
    email() {
      return this.$store.state.checkout.ordering.email;
    },
    deliveryTime() {
      return this.$store.state.checkout.ordering.deliveryTime;
    },
    shipments() {
      return this.$store.state.checkout.ordering.shipments;
    },
    shipmentPickup() {
      if (this.shipments && this.shipments.items) {
        return this.shipments.items.find(shipment => shipment.id === '2')
      } else {
        return {}
      }
    },
    payBudgetTotal() {
      if (this.account.budget - this.orderTotal > 0) {

        let total = this.account.budget - this.orderTotal;
        return new Intl.NumberFormat('ru-RU').format(total);
      } else {
        return 0;
      }
    },

    isAddressPickup() {
      return this.isDeliveryTab ? this.address.value : 'г. Южно-Сахалинск, пр. Мира, 58/1'
    },
  },

  methods: {
    applyCoupon() {
      this.$store
        .dispatch("CHECKOUT_APPLY_COUPON", {
          code: this.coupon,
        });
    },

    removeCoupon(coupon) {
      this.$store
        .dispatch("CHECKOUT_REMOVE_COUPON", {
          code: coupon,
        });
    },

    payBudget() {
      this.isPayBudget = !this.isPayBudget;
    },

    selectDeliveryTime(time) {
      this.deliveryDate = time.date;
      this.deliveryTimeTo = time.to;
      this.deliveryTimeFrom = time.from;
    },

    changeBonus() {
      if (this.priceBonus > 0)
        this.priceBonus = Math.round(this.priceBonus);

      if (this.priceBonus > this.bonusMaxCash)
        this.priceBonus = this.bonusMaxCash;
    },

    orderTotalFinal() {
      let orderTotalFinal = this.orderTotal;

      if (this.isPayBudget) {
        if (orderTotalFinal - this.account.budget > 0)
          orderTotalFinal -= this.account.budget;
        else
          orderTotalFinal = 0;

        this.bonusMaxCash = Math.round(orderTotalFinal);
        this.changeBonus();
      } else {
        this.bonusMaxCash = this.bonus.maxCash;
      }

      if (this.priceBonus > 0)
        orderTotalFinal -= this.priceBonus;

      if (orderTotalFinal < 0)
        orderTotalFinal = 0;

      orderTotalFinal = orderTotalFinal.toFixed(2);

      return orderTotalFinal.toLocaleString('ru-RU');
    },

    confirmOrder() {
      this.$store
        .dispatch("CHECKOUT_CONFIRM_ORDER", {
          'CITY': this.isAddressPickup,
          'FLOOR': this.floor.value,
          'ENTRANCE': this.entrance.value,
          'FIO': this.fio.value.trim(),
          'PHONE': this.phone.value,
          'EMAIL': this.email.value,
          'PAY_SYSTEM': this.paySystemValue,
          'BAD_QUALITY': this.badQualityValue,
          'USER_DESCRIPTION': this.description,
          'ORDER_CONFIRMATION': 'SMS',
          'OTHER_OPTIONS': this.specificsTravelList,
          'DELIVERY_TIME_DATE': this.isDeliveryTab ? this.deliveryDate : '1.01.2024',
          'DELIVERY_TIME_TO': this.isDeliveryTab ? this.deliveryTimeTo : '00:00',
          'DELIVERY_TIME_FROM': this.isDeliveryTab ? this.deliveryTimeFrom : '00:00',
          'ACCOUNT': this.isPayBudget,
          'LOGICTIM_PAYMENT_BONUS': this.priceBonus,
          'LOGICTIM_ADD_BONUS': this.bonus.bonusEnrollment,
          'DELIVERY_ID': this.isDeliveryTab ? 1 : 2,
        })
        .then(() => {
          this.$router.push("/checkout/ordering/success");
        });
    },
  },
};
</script>

<style scoped lang="sass">
.btn-simple
  text-align: left
</style>
