<template>
  <div v-if="isActive" class="modal-mask">
    <div class="modal-wrap">
      <div class="modal modal--info animated">
        <button type="button" class="modal__close" @click="closeModal">
          <svg class="icon icon-cross">
            <use xlink:href="#cross"></use>
          </svg>
        </button>
        <div class="review review--modal">
          <div class="resume-form__item">
            <div class="resume-form__input">
              <FormText
                label="Адрес доставки"
                :required="true"
                v-model="address"
                ref="addressInput"
              />
            </div>
          </div>
          <div class="addresses" v-if="suggestions.length > 0">
            <div
              class="address"
              v-for="suggestion in suggestions"
              :key="suggestion.value"
              @click="selectAddress(suggestion)"
            >
              {{ suggestion.value }}
            </div>
          </div>
          <div v-else>Данный адрес не найден</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormText from "@/components/forms/form-text";
import axios from "@/axios";
import { dadataUrl, dadataToken } from "@/utils/constants";

const headers = { headers: { Authorization: dadataToken } }

export default {
  name: "modal-address",

  props: {
    isActive: { type: Boolean, default: false },
    userAddress: { type: String, default: '' }
  },

  components: {
    FormText
  },

  data() {
    return {
      address: this.userAddress,
      suggestions: null
    };
  },

  watch: {
    address() {
      this.getSuggestions()
    }
  },

  created() {
    this.getSuggestions()
  },

  mounted() {
    console.log(this.$refs.addressInput)
  },

  methods: {
    closeModal() {
      this.$emit('closeModal')
    },

    getSuggestions() {
      axios.post(dadataUrl, { query: this.address }, headers)
        .then(({ data }) => {
          this.suggestions = data.suggestions
        })
    },

    selectAddress(address) {
      this.address = address.value

      if (address.data.house) {
        this.$store.commit('CHECKOUT_SET_ADDRESS', address.value)
        this.closeModal()
      }
    }
  },
}
</script>

<style scoped lang="sass">
.addresses
  max-height: 200px
  overflow-y: auto

.address
  padding: 5px
  &:not(:last-child)
    border-bottom: 1px solid #dedede
</style>
